import React from "react";
import { NavLink } from "react-router-dom";
// import './About2.css'; // Assuming you have a CSS file for custom styles

export default function About2(props) {
  return (
    <div>
      {props.headshow === "true" ? (
        <div className="container-fluid page-header py-5 mb-5 bg-primary text-white">
          <div className="container py-5">
            <h1 className="display-3 mb-3 animated slideInDown">
              About Us
            </h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink className="text-white" to="/">
                    Home
                  </NavLink>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  About
                </li>
              </ol>
            </nav>
          </div>
        </div>
      ) : null}

      <div className="container-fluid bg-light overflow-hidden px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0 d-flex justify-content-center flex-max-sm-column">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <img src="/img/2.jpg" alt="About Us" className="img-fluid rounded" style={{ maxHeight: "500px", maxWidth: "100%", marginTop: "20px", border:"4px solid green" }} />
            </div>
            <div
              className="col-lg-6 about-text pt-5 wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <h6 className="text-primary">About Us</h6>
                <h1 className="mb-4">
                  Empowering Sustainable Energy Solutions
                </h1>
                <p>
                  Cellera Power Care, established in 2016, has rapidly emerged
                  as a premier solar energy company in India, dedicated to
                  transforming the renewable energy landscape. With over eight
                  years of experience in the industry, we initially specialized
                  in rejuvenating lead-acid batteries and have since expanded
                  our expertise to include the commissioning of both On-Grid and
                  Off-Grid solar power generation systems. This diverse
                  background has positioned us as a trusted name in the solar
                  sector. At Cellera Power Care, our commitment goes beyond just
                  providing solar energy solutions; we aim to revolutionize how
                  energy is consumed and perceived. As a leading integrator of
                  solar rooftop systems, we focus on delivering customized solar
                  energy solutions tailored to the unique needs of residential,
                  commercial, and industrial properties. Our team of experts
                  works closely with clients to ensure seamless integration of
                  solar technology, making it accessible and effective.
                </p>
              </div>
            </div>
          </div>

          {/* Mission Section */}
          <div className="row g-0 mx-lg-0 mt-0 d-flex justify-content-center align-items-center">
            <div className="col-md-6 d-flex justify-content-center align-items-center mx-5"  style={{maxWidth:"200px"}}>
              <img
                src="/img/cpc-1.jpg"
                alt="Mission"
                className="img-fluid rounded"
                style={{
                  maxHeight: "300px",
                  maxWidth: "100%",
                  border: "4px solid green",
                  marginBottom: "0", // Remove bottom margin
                }}
              />
            </div>
            <div
              className="col-md-6 about-text wow fadeIn"
              data-wow-delay="0.5s"
            >
              <div className="p-3">
                <h2 className="text-primary mb-3">Our Mission</h2>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    • Empower individuals, businesses, and communities with
                    innovative solar energy solutions
                  </li>
                  <li className="mb-2">
                    • Provide reliable and sustainable solar power systems
                    tailored to clients' needs
                  </li>
                  <li className="mb-2">
                    • Help clients reduce energy costs while lowering their carbon
                    footprints
                  </li>
                  <li className="mb-2">
                    • Contribute to a cleaner, greener environment through
                    renewable energy
                  </li>
                  <li className="mb-2">
                    • Revolutionize energy consumption by making solar power
                    accessible and effective for all
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Vision Section */}
          <div className="row g-0 mx-lg-0 mt-1 d-flex justify-content-center flex-max-sm-column">
            <div
              className="col-md-6 about-text wow fadeIn order-md-1 order-2"
              data-wow-delay="0.5s"
            >
              <div className="p-3">
                <h2 className="text-primary mb-3">Our Vision</h2>
                <ul className="list-unstyled">
                  <li className="mb-2">
                    • Lead the global transformation towards widespread adoption
                    of solar energy
                  </li>
                  <li className="mb-2">
                    • Create a future where solar power is the primary energy
                    source worldwide
                  </li>
                  <li className="mb-2">
                    • Reduce dependency on non-renewable resources and fight
                    climate change
                  </li>
                  <li className="mb-2">
                    • Continuously innovate to enhance solar technologies and
                    solutions
                  </li>
                  <li className="mb-2">
                    • Foster a sustainable world powered by clean, renewable solar
                    energy
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center align-items-center order-md-2 order-1" style={{maxWidth:"200px"}}>
              <img
                src="/img/cpc-2.png"
                alt="Vision"
                className="img-fluid rounded"
                style={{
                  maxHeight: "300px",
                  maxWidth: "100%",
                  border: "4px solid green",
                  marginBottom: "0", // Remove bottom margin
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}