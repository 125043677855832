import React from 'react';
import './Gallery.css'; // We'll create this CSS file

const Gallery = () => {
  const images = [
    { id: 1, url: 'img/gallery/gi1.png', title: 'Solar 1' },
    { id: 2, url: 'img/gallery/gi2.png', title: 'Solar 2' },
    { id: 3, url: 'img/gallery/gi3.png', title: 'Solar 3' },
    { id: 4, url: 'img/gallery/gi4.png', title: 'Solar 4' },
    { id: 5, url: 'img/gallery/gi5.png', title: 'Solar 5' },
    { id: 6, url: 'img/gallery/gi6.png', title: 'Solar 6' },
    { id: 7, url: 'img/gallery/gi7.png', title: 'Solar 7' },
    { id: 8, url: 'img/gallery/gi8.png', title: 'Solar 8' },
    { id: 9, url: 'img/gallery/gi9.png', title: 'Solar 9' },
    { id: 10, url: 'img/gallery/gi10.png', title: 'Solar 10' },
    { id: 11, url: 'img/gallery/gi11.png', title: 'Solar 11' },
    { id: 12, url: 'img/gallery/gi12.png', title: 'Solar 12' },
  ];

  return (
    <div className="container-fluid py-4">
      <div className="row g-2">
        {images.map((image) => (
          <div key={image.id} className="col-6 col-sm-4 col-md-3 col-lg-2">
            <div className="gallery-item">
              <img
                src={image.url}
                alt={image.title}
                className="gallery-image"
              />
              <div className="gallery-overlay">
                <p className="gallery-title">{image.title}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;