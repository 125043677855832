import React from "react";
import { NavLink } from "react-router-dom";
import './About.css'; 

export default function About(props) {
  return (
    <div>
      {props.headshow === "true" ? (
        <div className="container-fluid page-header py-5 mb-5">
          <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              About Us
            </h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink className="text-white" to="/">
                    Home
                  </NavLink>
                </li>
                <li
                  className="breadcrumb-item text-white active"
                  aria-current="page"
                >
                  About
                </li>
              </ol>
            </nav>
          </div>
        </div>
      ) : null}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.1s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-users text-white"></i>
                </div>
                <h1 className="mb-0">2384+</h1>
              </div>
              <h5 className="mb-3">Happy Customers</h5>
              <span>
                Happy customers boost loyalty, spread positive word-of-mouth,
                and drive business growth.
              </span>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.3s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-check text-white"></i>
                </div>
                <h1 className="mb-0">52+</h1>
              </div>
              <h5 className="mb-3">Project Done</h5>
              <span>
                Completed projects showcase skills, achievements, and the
                ability to meet deadlines effectively.
              </span>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.5s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-award text-white"></i>
                </div>
                <h1 className="mb-0">27+</h1>
              </div>
              <h5 className="mb-3">Awards Win</h5>
              <span>
                Triumph at awards signifies recognition of excellence, inspiring
                further achievements.
              </span>
            </div>
            <div className="col-md-6 col-lg-3 wow fadeIn" data-wow-delay="0.7s">
              <div className="d-flex align-items-center mb-4">
                <div className="btn-lg-square bg-primary rounded-circle me-3">
                  <i className="fa fa-users-cog text-white"></i>
                </div>
                <h1 className="mb-0">38+</h1>
              </div>
              <h5 className="mb-3">Expert Workers</h5>
              <span>
                Skilled professionals driving innovation and efficiency in their
                fields.
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid bg-light overflow-hidden px-lg-0">
        <div className="container about px-lg-0">
          <div className="row g-0 mx-lg-0">
            <div
              className="col-lg-6 ps-lg-0 wow fadeIn about-image-container"
              data-wow-delay="0.1s"
              style={{ minHeight: "400px" }}
            >
              <div className="position-relative h-100 d-flex align-items-center justify-content-center">
                <img
                  className="img-fluid rounded"
                  src="img/2.jpg"
                  style={{
                    objectFit: "cover",
                    maxHeight: "350px",
                    border: "5px solid green",
                    margin: "20px 10px 20px 10px",
                  }}
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-lg-6 about-text py-5 wow fadeIn d-flex align-items-center"
              data-wow-delay="0.5s"
            >
              <div className="p-lg-5 pe-lg-0">
                <h6 className="text-primary">About Us</h6>
                <h1 className="mb-4">
                  Empowering Sustainable Energy Solutions
                </h1>
                <p>
                  Founded in 2016, Cellera Power Care is a leading solar energy
                  company in India with over eight years of expertise in
                  rejuvenating lead-acid batteries and commissioning both
                  On-Grid and Off-Grid solar power systems. We have established
                  ourselves as a trusted name in the solar industry, delivering
                  innovative and sustainable energy solutions. As a premier
                  solar rooftop integrator, we provide customized solar systems
                  for residential, commercial, and industrial clients. Our
                  mission is to empower individuals, businesses, and communities
                  to harness solar energy, reduce their carbon footprint, and
                  save on energy costs.
                </p>
                {/* <p><i className="fa fa-check-circle text-primary me-3"></i>Diam dolor diam ipsum</p>
      <p><i className="fa fa-check-circle text-primary me-3"></i>Aliqu diam amet diam et eos</p>
      <p><i className="fa fa-check-circle text-primary me-3"></i>Tempor erat elitr rebum at clita</p>
      <NavLink to="" className="btn btn-primary rounded-pill py-3 px-5 mt-3">Explore More</NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-xxl py-5"
        style={{ backgroundColor: "#ecefe0f1" }}
      >
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h6 className="text-primary">Team Member</h6>
            <h1 className="mb-4">Experienced Team Members</h1>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="d-flex">
                  <img
                    className="img-fluid w-75"
                    src="img/cpc-emp-3.jpg"
                    alt=""
                  />
                  <div className="team-social w-25">
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://www.facebook.com/profile.php?id=100066975584542"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://wa.me/+919090033366"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://instagram.com/CelleraPowerCare"
                    >
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="p-4">
                  <h5>Chandrashekhar Panigrahi</h5>
                  <span>Sr. Manager Operation</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="d-flex">
                  <img
                    className="img-fluid w-75"
                    src="img/chandan.jpg"
                    alt=""
                  />
                  <div className="team-social w-25">
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://www.facebook.com/profile.php?id=100066975584542"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://wa.me/+919090033366"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://instagram.com/CelleraPowerCare"
                    >
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="p-4">
                  <h5>Chandan Kumar Mahto</h5>
                  <span>Project Manager</span>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <div className="team-item rounded overflow-hidden">
                <div className="d-flex">
                  <img
                    className="img-fluid w-75"
                    src="img/cpc-emp-4.jpg"
                    alt=""
                  />
                  <div className="team-social w-25">
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://www.facebook.com/profile.php?id=100066975584542"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://wa.me/+919090033366"
                    >
                      <i className="fab fa-whatsapp"></i>
                    </NavLink>
                    <NavLink
                      className="btn btn-lg-square btn-outline-primary rounded-circle mt-3"
                      to="https://instagram.com/CelleraPowerCare"
                    >
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </div>
                </div>
                <div className="p-4">
                  <h5>Ritesh Kumar Naik</h5>
                  <span>Sr. Marketing Executive</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}