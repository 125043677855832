import React from 'react';
import { NavLink } from 'react-router-dom';

const Projects = () => {
    // Sample project data with descriptions
    const projectsData = [
        { image: "img/solar-img.jpeg", title:"Solar Panels", description: "We are pioneers in harnessing free energy through solar power generation." },
        { image: "img/street-light.jpeg", title:"Solar Street Light", description: "Lighting the Way with Sustainable Solar Solutions" },
        { image: "img/farming.jpg", title:"Solar Agricultural Solution",description: "Powering Agriculture with Clean, Sustainable Solar Energy" }
    ];

    return (
        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: "600px" }}>
                        <h6 className="text-primary">Our Projects</h6>
                        <h1 className="mb-4">Visit Our Latest Solar And Renewable Energy Projects</h1>
                    </div>
    
                    <div className="row g-4 portfolio-container wow fadeInUp" data-wow-delay="0.5s">
                        {/* Map through your project data and render project items */}
                        {projectsData.map((project, index) => (
                            <div className="col-lg-4 col-md-6 portfolio-item" key={index}>
                                <div className="portfolio-img rounded overflow-hidden">
                                    <img className="img-fluid" style={{ minHeight: "198px", maxHeight: '198px', minWidth: '356px' }} src={project.image} alt="" />
                                    {/* <div className="portfolio-btn">
                                        <NavLink className="mx-1" to="/" data-lightbox="portfolio"></NavLink>
                                        <NavLink className="mx-1" to="/"></NavLink>
                                    </div> */}
                                </div>
                                <div className="pt-3">
                                    <p className="text-primary mb-0" >{project.title}</p>
                                    <hr className="text-primary w-25 my-2" />
                                    <h5 className="lh-base" style={{color:"black"}}>{project.description}</h5>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Projects;