import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../Assets/img/cpc_logo1.png';

export default function Navbar() {
  // Function to handle the collapse of the navbar
  const handleNavLinkClick = () => {
    const navbarCollapse = document.getElementById('navbarCollapse');
    if (navbarCollapse.classList.contains('show')) {
      navbarCollapse.classList.remove('show');
    }
  };

  return (
    <div>
      {/* Top Info Bar */}
      <div className="container-fluid bg-dark p-0">
        <div className="row gx-0 d-none d-lg-flex">
          <div className="col-lg-7 px-5 text-start">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-map-marker-alt text-primary me-2"></small>
              <small>Bhubaneswar</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center">
              <small className="far fa-clock text-primary me-2"></small>
              <small>Mon - Sun : 09.00 AM - 09.00 PM</small>
            </div>
          </div>
          <div className="col-lg-5 px-5 text-end">
            <div className="h-100 d-inline-flex align-items-center me-4">
              <small className="fa fa-phone-alt text-primary me-2"></small>
              <small>+919090033366</small>
            </div>
            <div className="h-100 d-inline-flex align-items-center mx-n2">
              <a className="btn btn-square btn-link rounded-0 border-0 border-end border-secondary" href="https://www.facebook.com/profile.php?id=100066975584542"><i className="fab fa-facebook-f"></i></a>
              <a className="btn btn-square btn-link rounded-0" href="https://www.instagram.com/CelleraPowerCare"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>

      {/* Navbar */}
      <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0">
        <div className="container-fluid m-0 p-0">
          <NavLink to="/" className="navbar-brand d-flex align-items-center border-end px-0 px-lg-5">
            <img src={logo} className="img-fluid" width="80" height="80" alt="logo" />
            <h1 className="m-0 text-primary fs-6 ms-0 logoTitle">Cellera Power Care</h1>
          </NavLink>

          {/* Navbar Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ms-auto p-4 p-lg-0">
              <li className="nav-item">
                <NavLink to="/" className="nav-link" onClick={handleNavLinkClick}>Home</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/about" className="nav-link" onClick={handleNavLinkClick}>About</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/services" className="nav-link" onClick={handleNavLinkClick}>Service</NavLink>
              </li>
              <li className="nav-item">
                <NavLink to="/projects" className="nav-link" onClick={handleNavLinkClick}>Project</NavLink>
              </li>
            </ul>
            <NavLink to="/quote" className="btn-link rounded-0 p-4 d-none d-lg-block custom-btn" onClick={handleNavLinkClick}>
              Connect With Us<i className="fa fa-arrow-right ms-3"></i>
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}